/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import Container from 'components/Container';
import React from 'react';

interface SectionContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

const SectionContainer = React.forwardRef<HTMLDivElement, SectionContainerProps>(({ id, className, children }, ref) => {
  return (
    <Container>
      <ClassNames>
        {({ cx, css, theme }) => (
          <div
            id={id}
            ref={ref}
            css={cx(
              css({
                '@media(min-width: 960px)': {
                  minHeight: 'calc(700px - 100px)',
                },
                '@media(min-width: 1280px)': {
                  minHeight: 'calc(100vh - 100px)',
                },
                display: 'flex',
                flexBasis: '100%',
                flexGrow: 1,
                flexShrink: 0,
                minHeight: 'unset',
                padding: theme.spacing(8, 0),
              }),
              className,
            )}
          >
            {children}
          </div>
        )}
      </ClassNames>
    </Container>
  );
});

SectionContainer.displayName = 'SectionContainer';
export default SectionContainer;
