/** @jsx jsx */
/** @jsxFrag */
import { jsx } from '@emotion/react';
import BusinessModelListItem from 'components/BusinessModelListItem';
import SectionContainer from 'components/SectionContainer';
import SectionTitle from 'components/SectionTitle';
import europeBackground from 'images/europe.svg';
import React from 'react';
import Bounce from 'react-reveal/Bounce';
import { Link } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';
import { BusinessModelItem } from 'types/BusinessModel';
export interface HomePageSectionBusinessModelProps extends React.HTMLAttributes<HTMLDivElement> {
  buttonLabel?: string;
  items?: BusinessModelItem[];
  subtitle?: string;
  title?: string;
}

const HomePageSectionBusinessModel = React.forwardRef<HTMLDivElement, HomePageSectionBusinessModelProps>(
  ({ className, items, title, buttonLabel, subtitle }, ref) => {
    return (
      <SectionContainer
        ref={ref}
        className={className}
        css={(theme) => ({
          '& > p': {
            ...theme.typography.body1,
            lineHeight: '24px',
            margin: theme.spacing(1.5, 0),
            padding: 0,
            textAlign: 'center',
          },
          flexDirection: 'column',
          padding: theme.spacing(6, 0),
        })}
      >
        <SectionTitle>{title}</SectionTitle>
        {subtitle && (
          <Bounce>
            <p>{subtitle}</p>
          </Bounce>
        )}
        <div
          css={(theme) => ({
            '@media(min-width: 600px)': {
              alignItems: 'flex-end',
              backgroundPosition: 'center center',
              backgroundSize: 'contain',
              marginTop: theme.spacing(4),
            },
            alignItems: 'center',
            backgroundImage: europeBackground ? `url(${europeBackground})` : 'none',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            marginTop: theme.spacing(1),
          })}
        >
          <Bounce right>
            <div
              css={(theme) => ({
                background: 'rgba(255,255,255,.9)',
                boxShadow: theme.shadows[6],
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(3),
                position: 'relative',
              })}
            >
              {(items || []).map((item, idx) => (
                <BusinessModelListItem key={idx.toString()} item={item} />
              ))}
              {buttonLabel && (
                <Link
                  css={(theme) => ({
                    '&:hover': {
                      background: theme.palette.primary.dark,
                      boxShadow: theme.shadows[6],
                    },
                    alignItems: 'center',
                    alignSelf: 'center',
                    background: theme.palette.primary.main,
                    border: 'none',
                    borderRadius: '3px',
                    boxShadow: theme.shadows[3],
                    color: theme.palette.common.white,
                    cursor: 'pointer',
                    display: 'flex',
                    fontSize: '18px',
                    justifyContent: 'center',
                    marginTop: theme.spacing(2),
                    outline: 'unset',
                    padding: theme.spacing(1, 2),
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    transition: 'all 0.2s ease',
                  })}
                  to={routeUrl('contact', { locale: 'en', params: { locale: 'en' } })}
                >
                  {buttonLabel}
                </Link>
              )}
            </div>
          </Bounce>
        </div>
      </SectionContainer>
    );
  },
);

HomePageSectionBusinessModel.displayName = 'HomePageSectionBusinessModel';
export default HomePageSectionBusinessModel;
