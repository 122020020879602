/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

export interface ProductListCarouselProps {
  children: React.ReactNode;
  className?: string;
  interval?: number;
}

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProductListCarousel = React.forwardRef<HTMLDivElement, ProductListCarouselProps>(
  ({ className, children, interval = 6000 }, ref) => {
    const [index, setIndex] = React.useState<number>(0);

    const actionsRef = React.useRef<{ updateHeight: () => void } | null>(null);

    const handleChange = React.useCallback((i: number) => {
      setIndex(i);
    }, []);

    React.useEffect(() => {
      if (actionsRef.current && actionsRef.current.updateHeight) {
        setTimeout(() => {
          if (actionsRef.current) {
            actionsRef.current.updateHeight();
          }
        }, 150);
      }
    }, []);

    return (
      <ClassNames>
        {({ theme, cx, css }) => (
          <div
            ref={ref}
            className={cx(
              css({
                '@media(min-width: 960px)': { marginTop: '0', padding: theme.spacing(0, 2) },
                display: 'flex',
                flexDirection: 'column',
                img: {
                  maxHeight: 'auto',
                  width: '100%',
                },
                marginTop: '20px',
                padding: theme.spacing(0),
              }),
              className,
            )}
          >
            <AutoPlaySwipeableViews
              disableLazyLoading
              index={index}
              onChangeIndex={handleChange}
              animateHeight
              enableMouseEvents
              interval={interval}
              // @ts-ignore
              action={(actions: { updateHeight: () => void }) => {
                actionsRef.current = actions;
              }}
              className={cx(css({ paddingBottom: theme.spacing(1), position: 'relative', width: '100%' }))}
            >
              {React.Children.map(children, (child: any) => React.cloneElement(child))}
            </AutoPlaySwipeableViews>
            <ProductListCarouselPagination
              dots={React.Children.count(children)}
              index={index}
              onChange={handleChange}
            />
          </div>
        )}
      </ClassNames>
    );
  },
);

ProductListCarousel.displayName = 'ProductListCarousel';
export default ProductListCarousel;

const ProductListCarouselPagination: React.FC<{
  dots: number;
  index: number;
  onChange: (value: number) => void;
}> = ({ dots, index, onChange: handleChange }) => {
  const handleClick = React.useCallback(
    (idx) => {
      handleChange(idx);
    },
    [handleChange],
  );
  const children = [];

  for (let i = 0; i < dots; i += 1) {
    children.push(<ProductListCarouselPaginationDot key={i} index={i} active={i === index} onClick={handleClick} />);
  }

  return (
    <div css={(theme) => ({ alignSelf: 'center', display: 'flex', flexDirection: 'row', padding: theme.spacing(2) })}>
      {children}
    </div>
  );
};

const ProductListCarouselPaginationDot: React.FC<{
  active: boolean;
  className?: string;
  index: number;
  onClick: (value: number) => void;
}> = ({ className, active, index, onClick: handleClick }) => {
  const handleToggleClick = React.useCallback(() => {
    handleClick(index);
  }, [handleClick, index]);

  return (
    <ClassNames>
      {({ theme, cx, css }) => (
        <button
          type='button'
          aria-label='slide'
          className={cx(
            css({
              '@media(min-width: 1280px)': {
                height: 18,
                width: 18,
              },
              background: 'none',
              border: 0,
              cursor: 'pointer',
              height: 15,
              marginLeft: theme.spacing(1),
              outline: 'none',
              padding: 0,
              width: 15,
            }),
            className,
          )}
          onClick={handleToggleClick}
        >
          <div
            className={css({
              '@media(min-width: 1280px)': {
                height: 15,
                width: 15,
              },
              backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[300],
              borderRadius: 6,
              height: 12,
              margin: 0,
              width: 12,
            })}
          />
        </button>
      )}
    </ClassNames>
  );
};
