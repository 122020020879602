/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(({ className, children }, ref) => {
  return (
    <ClassNames>
      {({ cx, css, theme }) => (
        <div
          ref={ref}
          css={cx(
            css({
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 6,
              boxSizing: 'border-box',
              flexGrow: 1,
              margin: theme.spacing(3, 0),
              padding: theme.spacing(4, 3, 3, 3),
              position: 'relative',
              width: 295,
            }),
            className,
          )}
        >
          <div
            css={{
              background: theme.palette.common.white,
              border: '8px solid',
              borderColor: theme.palette.primary.main,
              borderRadius: 100,
              height: theme.spacing(6),
              left: '50%',
              position: 'absolute',
              top: '-24px',
              transform: 'translate(-50%, 0)',
              width: theme.spacing(6),
            }}
          />
          {children}
        </div>
      )}
    </ClassNames>
  );
});

Card.displayName = 'Card';
export default Card;
