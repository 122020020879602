/** @jsx jsx */
/** @jsxFrag */
import { ClassNames, jsx } from '@emotion/react';
import Card from 'components/Card';
import ProductListCarousel from 'components/ProductListCarousel';
import SectionContainer from 'components/SectionContainer';
import SectionTitle from 'components/SectionTitle';
import VideoComponent from 'components/VideoComponent';
import DinoLogo from 'images/DinoLogo.webp';
import dinozooCarouselImage from 'images/dinozoo_hp.webp';
import KakaduLogo from 'images/KakaduLogo.webp';
import kakaduCarouselImage from 'images/kakadu_hp.webp';
import superzoocarouselImage from 'images/super3.webp';
import SuperzooLogo from 'images/SuperzooLogo.webp';
import zoomarketImage from 'images/zoomarket.webp';
import React from 'react';
import HtmlParser from 'react-html-parser';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';

const carouselImages = [superzoocarouselImage, dinozooCarouselImage, kakaduCarouselImage];

interface HomePageSectionBrandStoresProps extends React.HTMLAttributes<HTMLDivElement> {
  content?: string;
  products?: BrandStores[];
  title?: string;
}

export interface BrandStores {
  countries: {
    href: string;
    title: string;
  }[];
  description: string;
  image: string;
  title: string;
}

const images = {
  dino: DinoLogo,
  kakadu: KakaduLogo,
  superzoo: SuperzooLogo,
  zoomarket: zoomarketImage,
};

const HomePageSectionBrandStores = React.forwardRef<HTMLDivElement, HomePageSectionBrandStoresProps>(
  ({ className, products, title, content }, ref) => {
    return (
      <ClassNames>
        {({ theme }) => (
          <SectionContainer
            ref={ref}
            className={className}
            css={{
              flexDirection: 'column',
              marginBottom: theme.spacing(4),
              minHeight: 'auto',
              padding: theme.spacing(4, 0),
            }}
          >
            <SectionTitle>{title}</SectionTitle>
            <Bounce bottom>
              <div
                css={{
                  '& p': {
                    lineHeight: '24px',
                  },
                  alignSelf: 'center',
                  margin: theme.spacing(4, 0),
                  maxWidth: '100%',
                  textAlign: 'center',
                }}
              >
                {content && HtmlParser(content)}
              </div>
            </Bounce>
            <div
              css={{
                '& > div': { display: 'flex' },
                alignItems: 'stretch',
                display: 'flex',
                flexGrow: 1,
                flexWrap: 'wrap',
                justifyContent: 'space-around',
              }}
            >
              {(products || []).map((item, idx) => (
                <Zoom key={idx.toString()} delay={600}>
                  <Card key={idx.toString()}>
                    <div
                      css={{
                        alignItems: 'center',
                        display: 'flex',
                        height: theme.spacing(10),
                        justifyContent: 'center',
                        paddingTop: theme.spacing(3),
                        width: '100%',
                      }}
                    >
                      <img
                        loading='lazy'
                        css={{ height: 54, maxWidth: '100%' }}
                        src={images[item.image]}
                        alt={item.title}
                      />
                    </div>
                    <div
                      css={{
                        '& > a': {
                          color: theme.palette.primary.main,
                          marginTop: theme.spacing(1),
                          textDecoration: 'none',
                        },
                        '& > a:hover': { textDecoration: 'underline' },
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      {(item.countries || []).map((i, index) => (
                        <a href={i.href} key={index.toString()} target='_blank' rel='noreferrer'>
                          {i.title}
                        </a>
                      ))}
                    </div>
                  </Card>
                </Zoom>
              ))}
            </div>
            <div
              css={{
                '@media(min-width: 960px)': {
                  '& > div': { padding: theme.spacing(1), width: '50%' },
                  alignItems: 'stretch',
                  flexDirection: 'row',
                },
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <VideoComponent stream='https://youtu.be/u789Kujb14A' />
              <ImageGallery />
            </div>
          </SectionContainer>
        )}
      </ClassNames>
    );
  },
);

HomePageSectionBrandStores.displayName = 'HomePageSectionBrandStores';
export default HomePageSectionBrandStores;

const ImageGallery: React.FC = () => {
  return (
    <div
      css={{
        '& img': {
          maxWidth: '100%',
        },
        display: 'flex',
        flexBasis: '50%',
        flexDirection: 'column',
        flexShrink: 0,
        position: 'relative',
      }}
    >
      <ProductListCarousel>
        {carouselImages.map((i, idx) => (
          <div
            key={idx.toString()}
            css={{
              '@media(min-width: 1280px)': {
                height: 'auto',
              },
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img src={i} alt='placek' draggable={false} />
          </div>
        ))}
      </ProductListCarousel>
    </div>
  );
};
