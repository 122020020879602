/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';
import YouTube from 'react-youtube';

export interface VideoComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  stream: string;
}

const VideoComponent: React.FC<VideoComponentProps> = ({ className, stream }) => {
  return (
    <ClassNames>
      {({ cx, css }) => (
        <YouTube
          css={cx(
            css({
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }),
            className,
          )}
          videoId={((s: string): string => {
            if (s.startsWith('http') && s.includes('youtube')) return new URL(s).searchParams.get('v') as string;
            if (s.startsWith('http')) return (s.match(/([^/]+)$/) || ['', ''])[1];
            return s;
          })(stream)}
          opts={{
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
            },
          }}
        />
      )}
    </ClassNames>
  );
};

VideoComponent.displayName = 'VideoComponent';
export default VideoComponent;
