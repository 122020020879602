import { SupportedLocale } from '@sprinx/knihovna-types';
import { routeUrl } from 'routesBuilder';
import { LocaleSwitchHrefs } from 'types/LocaleSwitchHrefs';

const supportedLocaleDef: { label: string; locale: SupportedLocale }[] = [
  { label: 'CS', locale: 'cs' },
  { label: 'EN', locale: 'en' },
];

const supportedLocaleCodes = supportedLocaleDef.map((o) => o.locale);

export function buildLocaleSwitchHrefs(
  cb: (p: { locale: SupportedLocale; routeUrl: typeof routeUrl }) => string,
): LocaleSwitchHrefs {
  return supportedLocaleDef.reduce(
    (a, l) => ({
      ...a,
      [l.locale]: {
        href: cb({
          locale: l.locale,
          routeUrl,
        }),
        label: l.label,
      },
    }),
    {} as LocaleSwitchHrefs,
  );
}

export const buildLocaleSwitchHrefsCheckLocale = (locale: unknown): locale is SupportedLocale =>
  typeof locale === 'string' && supportedLocaleCodes.includes(locale as any);
