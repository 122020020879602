/** @jsx jsx */
/** @jsxFrag */
import { ClassNames, jsx } from '@emotion/react';
import SectionContainer from 'components/SectionContainer';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
import CountUp from 'react-countup';
import Zoom from 'react-reveal/Zoom';

export interface BriefItem {
  description: string;
  title: number;
}
export interface HomePageSectionBriefProps extends React.HTMLAttributes<HTMLDivElement> {
  briefItems?: BriefItem[];
  title?: string;
}

const HomePageSectionBrief = React.forwardRef<HTMLDivElement, HomePageSectionBriefProps>(
  ({ className, briefItems, title }, ref) => {
    return (
      <SectionContainer ref={ref} className={className} css={{ flexDirection: 'column' }}>
        {title && <SectionTitle>{title}</SectionTitle>}
        <div
          css={{
            alignItems: 'flex-start',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {(briefItems || []).map((item, idx) => (
            <HomePageSectionBriefItem key={idx.toString()} title={item.title} description={item.description} />
          ))}
        </div>
      </SectionContainer>
    );
  },
);

HomePageSectionBrief.displayName = 'HomePageSectionBrief';
export default HomePageSectionBrief;

const HomePageSectionBriefItem: React.FC<{ className?: string; description: string; title: number }> = ({
  className,
  title,
  description,
}) => {
  const numberRef = React.useRef<HTMLDivElement>(null);
  const [renderItem, setRenderItem] = React.useState<boolean>(false);

  const handleCheckPosition = React.useCallback(() => {
    if (numberRef.current) {
      const itemRef = numberRef?.current?.offsetTop;
      const windowPosition = window.pageYOffset + window.innerHeight;

      if (itemRef <= windowPosition) {
        setRenderItem(true);
      }
    }
  }, [setRenderItem]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      handleCheckPosition();
      window.addEventListener('scroll', handleCheckPosition);
      return () => {
        window.removeEventListener('scroll', handleCheckPosition);
      };
    }
    return undefined;
  }, [handleCheckPosition]);

  return (
    <ClassNames>
      {({ cx, css, theme }) => (
        <div
          className={cx(
            css({
              '@media(min-width: 600px)': {
                margin: theme.spacing(3, 0),
                padding: theme.spacing(5),
              },
              '@media(min-width: 960px)': {
                flexBasis: '33%',
                maxWidth: '33%',
              },
              alignItems: 'center',
              display: 'flex',
              flexBasis: '50%',
              flexDirection: 'column',
              flexGrow: 1,
              flexShrink: 0,
              justifyContent: 'center',
              margin: theme.spacing(1, 0),
              maxWidth: '50%',
              padding: theme.spacing(2),
            }),
            className,
          )}
        >
          <div
            ref={numberRef}
            css={{
              color: theme.palette.primary.main,
              fontSize: theme.spacing(7),
              fontWeight: 600,
              height: 56,
              letterSpacing: '.9px',
              lineHeight: '56px',
              marginBottom: theme.spacing(3),
              textAlign: 'center',
            }}
          >
            <Zoom>{renderItem ? <CountUp duration={5} start={0} end={title} /> : title}</Zoom>
          </div>
          <div
            css={css({
              color: theme.palette.text.secondary,
              fontSize: 18,
              letterSpacing: '.3px',
              lineHeight: '24px',
              textAlign: 'center',
            })}
          >
            {description}
          </div>
        </div>
      )}
    </ClassNames>
  );
};

HomePageSectionBriefItem.displayName = 'HomePageSectionBriefItem';
