/** @jsx jsx */
/** @jsxFrag */
import { jsx } from '@emotion/react';
import SectionContainer from 'components/SectionContainer';
import SectionTitle from 'components/SectionTitle';
import backgroundImage from 'images/placek-mission.webp';
import React from 'react';
import HtmlParser from 'react-html-parser';
import Bounce from 'react-reveal/Bounce';

interface HomePageSectionMissionProps extends React.HTMLAttributes<HTMLDivElement> {
  content?: string;
  title?: string;
}

const HomePageSectionMission = React.forwardRef<HTMLDivElement, HomePageSectionMissionProps>(
  ({ className, content, title }, ref) => {
    return (
      <div
        css={(theme) => ({
          '@media(min-width: 600px)': { paddingBottom: theme.spacing(40) },
          '@media(min-width: 960px)': { paddingBottom: theme.spacing(50) },
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: theme.spacing(8),
          paddingBottom: theme.spacing(30),
          width: '100%',
        })}
      >
        <SectionContainer
          ref={ref}
          className={className}
          css={() => ({
            '@media(min-width: 960px)': { minHeight: 'unset' },
            flexDirection: 'column',
            padding: 0,
          })}
        >
          {title && <SectionTitle>{title}</SectionTitle>}
          {content && (
            <div
              css={(theme) => ({
                '& p': {
                  ...theme.typography.body1,
                  lineHeight: '24px',
                  margin: theme.spacing(1.5, 0),
                  padding: 0,
                },
                '@media(min-width: 600px)': { maxWidth: 800 },
                alignSelf: 'center',
                maxWidth: 480,
                padding: theme.spacing(2),
                textAlign: 'center',
                width: '100%',
              })}
            >
              <Bounce bottom>{HtmlParser(content)}</Bounce>
            </div>
          )}
        </SectionContainer>
      </div>
    );
  },
);

HomePageSectionMission.displayName = 'HomePageSectionMission';
export default HomePageSectionMission;
