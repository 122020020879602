/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import { AfterPage, AfterPageProps } from '@sprinx/after-razzle';
import { SupportedLocale } from '@sprinx/knihovna-types';
import articleData from 'api/articleData';
import Page, { PageHeadline } from 'components/Page';
import MainLayout from 'layouts/MainLayout';
import ErrorPage from 'pages/ErrorPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import HtmlParser from 'react-html-parser';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import { LocaleSwitchHrefs } from 'types/LocaleSwitchHrefs';
import { PageLayout } from 'types/PageLayout';
import { buildLocaleSwitchHrefs, buildLocaleSwitchHrefsCheckLocale } from 'utils/buildLocaleSwitchHrefs';
import EngagementPageGallery from './components/EngagementPageGallery';
export interface ExamplesOfOurCommunityEngagementPageProps
  extends AfterPageProps<ExamplesOfOurCommunityEngagementPageRouteParams> {
  article?: {
    layout: PageLayout;
    subtitle?: string;
    text?: string;
    title?: string;
  };
  locale?: SupportedLocale;
  localeHrefs?: LocaleSwitchHrefs;
}

interface ExamplesOfOurCommunityEngagementPageRouteParams {
  locale: string;
}

const carouselImages = [
  { alt: 'senior gift', src: `/gift-senior.webp`, title: '' },
  { alt: 'gift', src: `/gift1.webp`, title: '' },
  { alt: 'Chov krouzek', src: `/chov-krouzek.webp`, title: '' },
  { alt: 'Plzenska zoo', src: `/plzenskazoo.webp`, title: '' },
  { alt: 'Zoopark zajezd', src: `/zoopark-zajezd.webp`, title: '' },
];

const ExamplesOfOurCommunityEngagementPage: AfterPage<
  ExamplesOfOurCommunityEngagementPageRouteParams,
  ExamplesOfOurCommunityEngagementPageProps
> = ({ article, error, locale, localeHrefs }) => {
  if (error) return <ErrorPage error={error} />;
  if (!article) return <NotFoundPage />;

  const { title, text, subtitle, layout } = article;

  return (
    <MainLayout {...layout} locale={locale} localeHrefs={localeHrefs}>
      <ClassNames>
        {({ theme }) => (
          <Page
            pageTitle={article.title}
            css={{
              padding: theme.spacing(1),
            }}
          >
            <PageHeadline>{title}</PageHeadline>
            <div
              css={{
                '@media(min-width: 960px)': {
                  '& > div': {
                    width: '50%',
                  },
                  flexBasis: '50%',
                  flexDirection: 'row',
                  flexGrow: 1,
                  flexShrink: 0,
                  padding: theme.spacing(4, 2),
                },
                display: 'flex',
                flexDirection: 'column',
                maxWidth: theme.breakpoints.values.lg,
                padding: theme.spacing(2, 0),
                width: '100%',
              }}
            >
              <div
                css={{
                  '@media(min-width: 600px)': {
                    padding: theme.spacing(0, 2),
                  },
                  textAlign: 'justify',
                  width: '100%',
                }}
              >
                <h3 css={{ fontSize: 22, marginBottom: theme.spacing(2) }}>
                  <Pulse>{subtitle}</Pulse>
                </h3>
                <Fade bottom timeout={2000} delay={600}>
                  {text && HtmlParser(text)}
                  {locale == 'cs' ? (
                    <div css={{ display: 'flex', gap: 12, marginTop: 20 }}>
                      Další příklady:
                      <a href='https://www.besmarthead.com/sk/profile/super-zoo-sk/127493/activities'>SmartHead</a>
                    </div>
                  ) : (
                    <div css={{ display: 'flex', gap: 12, marginTop: 20 }}>
                      Other examples:
                      <a href='https://www.besmarthead.com/sk/profile/super-zoo-sk/127493/activities'>SmartHead</a>
                    </div>
                  )}
                </Fade>
              </div>
              <EngagementPageGallery images={carouselImages} />
            </div>
          </Page>
        )}
      </ClassNames>
    </MainLayout>
  );
};

ExamplesOfOurCommunityEngagementPage.getInitialProps = async ({ req, match }) => {
  const { locale } = match.params;

  if (!buildLocaleSwitchHrefsCheckLocale(locale)) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(`ExamplesOfOurCommunityEngagementPage.getInitialProps: locale '${locale}' not supported.`);
    }

    return {
      redirectTo: '/en',
    };
  }

  const article = req?.$article
    ? req.$article
    : await articleData.get({ locale, path: 'examplesofourcommunityengagement' });
  const localeHrefs = buildLocaleSwitchHrefs(({ locale, routeUrl }) =>
    routeUrl('examplesofourcommunityengagement', {
      locale,
      params: {
        locale,
      },
    }),
  );

  return {
    article,
    locale,
    localeHrefs,
  };
};

export default ExamplesOfOurCommunityEngagementPage;
