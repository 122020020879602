/** @jsx jsx */
/** @jsxFrag */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

const SectionTitle: React.FC<{ children: React.ReactNode; className?: string }> = ({ className, children }) => {
  return (
    <ClassNames>
      {({ theme, cx, css }) => (
        <h2
          css={cx(
            className,
            css({
              ...theme.typography.h2,
            }),
          )}
        >
          {children}
        </h2>
      )}
    </ClassNames>
  );
};

SectionTitle.displayName = 'SectionTitle';

export default SectionTitle;
