/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import HtmlParser from 'react-html-parser';
import { BusinessModelItem } from 'types/BusinessModel';

const BusinessModelListItem: React.FC<{ item: BusinessModelItem }> = ({ item }) => {
  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{
            '@media(min-width: 600px)': { alignItems: 'flex-start', flexDirection: 'row' },
            '@media(min-width: 960px)': { padding: theme.spacing(2) },
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(1),
            maxWidth: 320,
            width: '100%',
          }}
        >
          <div
            css={{
              '@media(min-width: 600px)': { paddingLeft: theme.spacing(2) },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              css={{
                '@media(min-width: 600px)': { textAlign: 'left' },
                color: theme.palette.primary.main,
                fontSize: 24,
                fontWeight: 600,
                marginBottom: theme.spacing(0.5),
                textAlign: 'center',
              }}
            >
              {item.title}
            </span>
            <span
              css={{
                '& > a': {
                  '&:hover': {
                    color: theme.palette.primary.main,
                  },
                  color: theme.palette.text.secondary,
                },
                color: theme.palette.text.secondary,
                fontSize: 14,
                textAlign: 'justify',
              }}
            >
              {HtmlParser(item.description)}
            </span>
          </div>
        </div>
      )}
    </ClassNames>
  );
};
BusinessModelListItem.displayName = 'BusinessModelListItem';

export default BusinessModelListItem;
