/** @jsx jsx */
import { jsx } from '@emotion/react';
import { AfterPage, AfterPageProps } from '@sprinx/after-razzle';
import { SupportedLocale } from '@sprinx/knihovna-types';
import articleData from 'api/articleData';
import Page from 'components/Page';
import MainLayout from 'layouts/MainLayout';
import ErrorPage from 'pages/ErrorPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import { RouteOptions } from 'routesBuilder';
import { BusinessModelItem } from 'types/BusinessModel';
import { LocaleSwitchHrefs } from 'types/LocaleSwitchHrefs';
import { PageLayout } from 'types/PageLayout';
import { buildLocaleSwitchHrefs, buildLocaleSwitchHrefsCheckLocale } from 'utils/buildLocaleSwitchHrefs';
import HomePageSectionBrandStores, { BrandStores } from './components/HomePageSectionBrandStores';
import HomePageSectionBrief, { BriefItem } from './components/HomePageSectionBrief';
import HomePageSectionBusinessModel from './components/HomePageSectionBusinessModel';
import HomePageSectionIntroduction from './components/HomePageSectionIntroduction';
import HomePageSectionMission from './components/HomePageSectionMission';

export interface Introduction {
  signature: string;
  subtitle: string;
  text: { description?: string; href?: RouteOptions; title: string }[];
  title: string;
}

export interface HomePageProps extends AfterPageProps<HomePageRouteParams> {
  article?: {
    body: string;
    brandStores?: {
      content?: string;
      products?: BrandStores[];
      title?: string;
    };
    brief?: {
      briefItems?: BriefItem[];
      title?: string;
    };
    business?: {
      buttonLabel?: string;
      items?: BusinessModelItem[];
      subtitle?: string;
      title?: string;
    };
    contact?: {
      title: string;
    };
    introduction?: Introduction;
    layout: PageLayout;
    mission?: {
      body?: string;
      title?: string;
    };
    title: string;
  };
  locale?: SupportedLocale;
  localeHrefs?: LocaleSwitchHrefs;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HomePageRouteParams {
  locale: string;
}

const HomePage: AfterPage<HomePageRouteParams, HomePageProps> = ({ article, error, locale, localeHrefs }) => {
  if (error) return <ErrorPage error={error} />;
  if (!article) return <NotFoundPage />;

  const { layout } = article;

  return (
    <MainLayout {...layout} locale={locale} localeHrefs={localeHrefs}>
      <Page pageTitle={article.title}>
        {article.introduction && <HomePageSectionIntroduction introductionData={article.introduction} locale={'en'} />}
        {article.brief && (
          <HomePageSectionBrief briefItems={article?.brief?.briefItems} title={article?.brief?.title} />
        )}
        {article.mission && <HomePageSectionMission title={article?.mission?.title} content={article?.mission?.body} />}
        {article.business && (
          <HomePageSectionBusinessModel
            items={article.business?.items}
            buttonLabel={article.business?.buttonLabel}
            title={article.business?.title}
            subtitle={article.business?.subtitle}
          />
        )}
        {article.brandStores && (
          <HomePageSectionBrandStores
            products={article.brandStores?.products}
            title={article.brandStores?.title}
            content={article.brandStores?.content}
          />
        )}
      </Page>
    </MainLayout>
  );
};

HomePage.getInitialProps = async ({ req, match }) => {
  const { locale } = match.params;

  if (!buildLocaleSwitchHrefsCheckLocale(locale)) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(`HomePage.getInitialProps: locale '${locale}' not supported.`);
    }

    return {
      redirectTo: '/en',
    };
  }

  const article = req?.$article ? req.$article : await articleData.get({ locale, path: 'home' });

  const localeHrefs = buildLocaleSwitchHrefs(({ locale, routeUrl }) =>
    routeUrl('home', {
      locale,
      params: {
        locale,
      },
    }),
  );

  return {
    article,
    locale,
    localeHrefs,
  };
};

export default HomePage;
