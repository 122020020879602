/** @jsx jsx */
/** @jsxFrag */
import { jsx } from '@emotion/react';
import ProductListCarousel from 'components/ProductListCarousel';
import React from 'react';
import { GalleryImage } from 'types/GalleryImage';

interface HomePageSectionMissionProps extends React.HTMLAttributes<HTMLDivElement> {
  images: GalleryImage[];
}

const HomePageSectionMission = React.forwardRef<HTMLDivElement, HomePageSectionMissionProps>(({ images = [] }, ref) => {
  return (
    <div
      ref={ref}
      css={(theme) => ({
        '& img': {
          maxHeight: 'auto',
          maxWidth: '100%',
        },
        '@media(min-width: 960px)': {
          padding: 0,
        },
        alignItems: 'center',
        display: 'flex',
        flexBasis: '50%',
        flexGrow: 1,
        flexShrink: 0,
        justifyContent: 'center',
        minHeight: 380,
        padding: theme.spacing(3, 0),
        position: 'relative',
      })}
    >
      <ProductListCarousel>
        {images.map((i, idx) => (
          <div
            key={idx.toString()}
            css={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: 300,
              width: '100%',
            }}
          >
            <img src={i.src} alt={i.alt} title={i.title} draggable={false} />
          </div>
        ))}
      </ProductListCarousel>
    </div>
  );
});

HomePageSectionMission.displayName = 'HomePageSectionMission';
export default HomePageSectionMission;
