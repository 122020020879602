/*
 * !!! This is only example / skeleton of an remote resource.
 * !!! Have to create implementation.
 */
import { CancelablePromiseType } from '@sprinx/after-razzle';
import { RouteOptions } from 'routesBuilder';
import { Article } from 'types/Article';
import apiClient from './appApi';

export default {
  get: getResource,
};

function getResource(params: { locale: string; path: RouteOptions }): CancelablePromiseType<Article> {
  return apiClient.get<Article, { path: string }>('/:locale/:path', params);
}
