/** @jsx jsx */
/** @jsxFrag  */
import { ClassNames, jsx } from '@emotion/react';
import { SupportedLocale } from '@sprinx/knihovna-types';
import Container from 'components/Container';
import SectionContainer from 'components/SectionContainer';
import React from 'react';
import HtmlParser from 'react-html-parser';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';
import { Introduction } from '../HomePage';

export interface HomePageSectionIntroductionProps extends React.HTMLAttributes<HTMLDivElement> {
  introductionData: Introduction;
  locale: SupportedLocale;
}

const HomePageSectionIntroduction = React.forwardRef<HTMLDivElement, HomePageSectionIntroductionProps>(
  ({ className, introductionData: { title, subtitle, signature, text }, locale }, ref) => {
    return (
      <ClassNames>
        {({ theme }) => (
          <>
            <SectionContainer
              ref={ref}
              className={className}
              css={{
                '@media(min-width: 600px)': {
                  paddingBottom: theme.spacing(0),
                },
                '@media(min-width: 960px)': {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginBottom: '50px',
                  minHeight: 'calc(100vh - 250px)',
                },
                alignItems: 'center',
                backgroundImage: 'url("/Prodejna.webp")',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                flexDirection: 'column',
                marginBottom: '200px',
                paddingBottom: theme.spacing(0),
                paddingTop: 0,
              }}
            >
              <div
                css={{
                  '@media(min-width: 600px)': {},
                  '@media(min-width: 960px)': {
                    background: 'rgb(255,255,255, 0.8)',
                    bottom: '-165px',
                    minHeight: 'auto',
                    padding: '20px',
                    width: '100%',
                  },

                  bottom: '-310px',
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '300px',
                  position: 'relative',
                  width: '100%',
                }}
              >
                <Pulse>
                  <h1
                    css={{
                      '@media(min-width: 600px)': {
                        fontSize: theme.spacing(7),
                      },
                      color: theme.palette.primary.main,
                      fontSize: theme.spacing(4.5),
                      marginBottom: theme.spacing(3),
                      textAlign: 'center',
                    }}
                  >
                    {HtmlParser(title)}
                  </h1>
                </Pulse>
                <Fade bottom timeout={2000} delay={1000}>
                  <h2
                    css={{
                      '& i': { bottom: -16, fontSize: 14, position: 'absolute', right: 0 },
                      '@media(min-width: 600px)': { fontSize: theme.spacing(3) },
                      color: theme.palette.text.primary,
                      fontSize: theme.spacing(2.5),
                      position: 'relative',
                      textAlign: 'center',
                    }}
                  >
                    {subtitle}
                    <br />
                    <i>{signature}</i>
                  </h2>
                </Fade>
              </div>
            </SectionContainer>
            <Container>
              <div
                css={{
                  '& > div': {
                    '& > p, & a': {
                      color: theme.palette.primary.main,
                      fontSize: 24,
                      fontWeight: theme.typography.fontWeightMedium,
                      textAlign: 'left',
                      textDecoration: 'none',
                    },
                    '& a:hover': { textDecoration: 'underline' },
                    '@media(min-width: 600px)': {
                      padding: theme.spacing(2),
                      width: '33%',
                    },
                    '@media(min-width: 960px)': {
                      padding: theme.spacing(4),
                    },
                    padding: theme.spacing(2, 0),
                    textAlign: 'justify',
                    width: '100%',
                  },
                  '@media(min-width: 600px)': { flexDirection: 'row' },
                  alignItems: 'flex-start',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingBottom: 'theme.spacing(8)',
                }}
              >
                {(text || []).map((i, idx) => (
                  <div key={idx.toString()}>
                    <Zoom delay={1000}>
                      {i.href ? (
                        <Link to={routeUrl(i.href, { locale, otherParamsToQs: true, params: { locale: locale } })}>
                          {i.title}
                        </Link>
                      ) : (
                        <p>{i.title}</p>
                      )}
                      {i.description}
                    </Zoom>
                  </div>
                ))}
              </div>
            </Container>
          </>
        )}
      </ClassNames>
    );
  },
);

HomePageSectionIntroduction.displayName = 'HomePageSectionIntroduction';
export default HomePageSectionIntroduction;
